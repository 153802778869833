'use strict';

const LogFuncionario = {

    tabela: 'public.log_funcionario',

    inserir: async (dbClient, transactionHandler = null, dados) => {
        const { idFuncionario, evento, url, codigo, prioridade, arquivo, linha, categoria } = dados;
        
        let insert = dbClient.insert({
            id_funcionario: idFuncionario,
            evento: evento,
            url: url,
            codigo: codigo,
            prioridade: prioridade,
            arquivo: arquivo,
            linha: linha,
            categoria: categoria
        })
            .into(LogFuncionario.tabela);
    
        if (transactionHandler) {
            insert = insert.transacting(transactionHandler);
        }
        await insert;
    }
};

module.exports = LogFuncionario;