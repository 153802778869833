import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import ButtonView from '@ckeditor/ckeditor5-ui/src/button/buttonview';

import iconExpressoesMatematicas from '../../icones/formula.svg';
import { CalcularFormulas, calcularFormulas } from '../commands/expressoesMatematicasCommands';

export default class ExpressoesMatematicas extends Plugin {
    init() {
        const editor = this.editor;

        editor.commands.add(
            'calcularFormulas', new CalcularFormulas( editor )
        );
        
        editor.ui.componentFactory.add('expressoesMatematicas', () => {
            const botaoExpressoesMatematicas = new ButtonView();

            botaoExpressoesMatematicas.set({
                label: 'Calcular fórmulas',
                icon: iconExpressoesMatematicas,
                tooltip: true
            });

            botaoExpressoesMatematicas.on('execute', () => {
                calcularFormulas(editor);
                editor.editing.view.focus();
            });

            return botaoExpressoesMatematicas;
        });
    }
}