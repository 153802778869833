'use strict';

const siglaSexoParaFhir = (sigla) => {
    switch (sigla) {
        case 'F':
            return 'female';
        case 'M':
            return 'male';
        case 'O':
            return 'other';
        case 'I':
        default:
            return 'unknown';
    }
}

const fhirSexoParaSigla = (sexo) => {
    switch (sexo) {
        case 'female':
            return 'F';
        case 'male':
            return 'M';
        case 'other':
            return 'O';
        case 'unknown':
        default:
            return 'I';
    }
}

module.exports = { siglaSexoParaFhir, fhirSexoParaSigla };