/**
 * Unificar as regras de negócio para recuperar ACCESS_TOKEN, REFRESH_TOKEN e EVENT_BUS para diferentes navegadores.
 */
import Constants from '../constants';
import RequestCommon from '../requests';
import Cookies from 'js-cookie';

const useQuery = () => {
    return new URLSearchParams(window.location.search);
};

const getItemLS = (item) => {
    try {
        return localStorage.getItem(item);
    } catch (e) {
        console.log(`ATENCAO: ${e.message}`);
        console.log(
            `ATENCAO: Entrar em chrome://settings/cookies, e adicionar https://login.telemedicina.ufsc.br na exceção de 'Sites que podem usar cookies sempre'.`
        );
        return null;
    }
};

const setItemLS = (key, item) => {
    try {
        localStorage.setItem(key, item);
    } catch (e) {
        console.log(`ATENCAO: ${e.message}`);
        console.log(
            `ATENCAO: Entrar em chrome://settings/cookies, e adicionar https://login.telemedicina.ufsc.br na exceção de 'Sites que podem usar cookies sempre'.`
        );
    }
};

const getCookiesProps = () => {
    const host = window.location.host;
    const chainWords = host.split('.');
    if (chainWords.length > 2) {
        return {
            domain: chainWords.slice(1).join('.'),
        };
    } else {
        return undefined;
    }
};

const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

/**
 * GET ACCESS TOKEN
 * 1 - Recupera através de um redirecionamento do login.
 * 2 - ou recupera através do cookie;
 * 3 - ou recupera através do localStorage;
 * 4 - Retorna null para access token não encontrado.
 */
const getAccessToken = () => {
    const query = useQuery();
    let accessToken = query.get(Constants.QUERY_PARAMS.ACCESS_TOKEN);
    if (accessToken) return accessToken;

    accessToken = Cookies.get(Constants.COOKIES_ITEMS.ACCESS_TOKEN);
    if (accessToken) return accessToken;

    accessToken = getItemLS(Constants.LOCALSTORAGE_ITEMS.ACCESS_TOKEN);
    // Problema no firefox, setItem(null) é igual a "null"
    if (accessToken && accessToken != 'null') return accessToken;

    return null;
};

const setAccessToken = (accessToken) => {
    if (accessToken && accessToken != 'null') {
        setItemLS(Constants.LOCALSTORAGE_ITEMS.ACCESS_TOKEN, accessToken);
        Cookies.set(Constants.COOKIES_ITEMS.ACCESS_TOKEN, accessToken, getCookiesProps());
    }
};

const getRefreshToken = () => {
    let refreshToken = getItemLS(Constants.LOCALSTORAGE_ITEMS.REFRESH_TOKEN);
    if (refreshToken) return refreshToken;

    refreshToken = Cookies.get(Constants.COOKIES_ITEMS.REFRESH_TOKEN);
    if (refreshToken) return refreshToken;
    return null;
};

const setRefreshToken = (refreshToken) => {
    setItemLS(Constants.LOCALSTORAGE_ITEMS.REFRESH_TOKEN, refreshToken);
    Cookies.set(Constants.COOKIES_ITEMS.REFRESH_TOKEN, refreshToken, getCookiesProps());
};

const setUserInfoSerial = (userInfoSerial) => {
    setItemLS(Constants.LOCALSTORAGE_ITEMS.USER_INFO, userInfoSerial);
};

const setUserInfo = (userInfo) => {
    const userInfoSerial = JSON.stringify(userInfo);
    setUserInfoSerial(userInfoSerial);
};

const getUserInfo = () => {
    const userInfoSerial = getItemLS(Constants.LOCALSTORAGE_ITEMS.USER_INFO);
    const userInfo = JSON.parse(userInfoSerial);
    return userInfo;
};

const getUserInfoAsync = async (config) => {
    let userInfo = getUserInfo();
    if (userInfo) return userInfo;

    const response = await RequestCommon(config).me();
    userInfo = response.data;
    return userInfo;
};

const setEventBus = (message) => {
    setItemLS(Constants.LOCALSTORAGE_ITEMS.EVENT_BUS, JSON.stringify(message));
    // Implementar para enviar notificações para o mesmo domain
    // 1 - Criar uma forma de recuperar qual aba (id) já executou o evento por cookie
    // 2 - Utilizar sessionStorage para armazenar se já foi executada.
    //Cookies.set(Constants.COOKIES_ITEMS.EVENT_BUS_ACTION, message.acao, getCookiesProps());
};

const getEventBus = () => {
    return JSON.parse(getItemLS(Constants.LOCALSTORAGE_ITEMS.EVENT_BUS));
};

const clear = () => {
    localStorage.removeItem(Constants.LOCALSTORAGE_ITEMS.ACCESS_TOKEN);
    localStorage.removeItem(Constants.LOCALSTORAGE_ITEMS.EVENT_BUS);
    localStorage.removeItem(Constants.LOCALSTORAGE_ITEMS.REFRESH_TOKEN);
    localStorage.removeItem(Constants.LOCALSTORAGE_ITEMS.USER_INFO);
    Cookies.remove(Constants.COOKIES_ITEMS.ACCESS_TOKEN, getCookiesProps());
    Cookies.remove(Constants.COOKIES_ITEMS.REFRESH_TOKEN, getCookiesProps());
};

const _removeQueryParam = (param) => {
    if (window.history && window.history.replaceState) {
        let url = new URL(window.location.href);
        url.searchParams.delete(param);
        window.history.replaceState({ path: url.href }, '', url.href);
    } else {
        // Alternativa para navegadores antigos
        console.warn('Este navegador não suporta a API history. Parâmetro Token não removido da URL.');
    }
};

const removeAllQueryParam = () => {
    _removeQueryParam(Constants.QUERY_PARAMS.ACCESS_TOKEN);
    _removeQueryParam(Constants.QUERY_PARAMS.REFRESH_TOKEN);
};

export default {
    getAccessToken,
    getRefreshToken,
    setAccessToken,
    setRefreshToken,
    setUserInfo,
    setUserInfoSerial,
    getUserInfo,
    getUserInfoAsync,
    setEventBus,
    getEventBus,
    clear,
    removeAllQueryParam,
};
