import React, {} from 'react';
import { makeStyles } from "@material-ui/core/styles";
import SttHeading from '@stt-componentes/core/dist/lib/SttTypography/SttHeading';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import { connect } from 'react-redux';

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(3),
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column'
    }
}));

const Erro = (props) => {
    const classes = useStyles();
    const { message } = props;

    return (
        <div className={classes.root}>
            <SttHeading variant="h1">
                <ErrorOutlineIcon />
                Erro
            </SttHeading>
            <SttHeading variant="h4">
                {message}
            </SttHeading>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        message: state.index.message,
    };
};

export default connect(mapStateToProps, null)(Erro);
