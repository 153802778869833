"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
/**
 * Definições CSS para componentes de input de formulário
 * 
 * @param {Object} theme 
 */
var styles = function styles(theme) {
  return {
    label: {
      color: theme.palette.text.secondary,
      '&$focused': {
        color: "".concat(theme.palette.text.secondary)
      },
      '&$error': {
        color: "".concat(theme.palette.error.main)
      }
    },
    focused: {},
    input: {
      lineHeight: 1,
      backgroundColor: '#ffffff',
      color: theme.palette.text.secondary,
      '& .MuiInputBase-root': {
        lineHeight: 1
      },
      '&$disabled $notchedOutline': {
        borderWidth: '2px',
        color: '#c2c2c2',
        borderColor: '#c2c2c2'
      },
      '&$cssfocused $notchedOutline': {
        borderWidth: '1px',
        borderColor: "".concat(theme.palette.text.secondary)
      },
      '&$error $notchedOutline': {
        borderWidth: '2px',
        borderColor: "".concat(theme.palette.error.main)
      },
      '&protocol': {
        fontFamily: '"Noto Sans"'
      }
    },
    cssfocused: {
      color: "".concat(theme.palette.text.secondary)
    },
    notchedOutline: {
      borderWidth: '1px',
      borderColor: "".concat(theme.palette.text.secondary)
    },
    disabled: {
      backgroundColor: '#f2f2f2'
    },
    error: {}
  };
};
var _default = styles;
exports["default"] = _default;