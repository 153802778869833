import AxiosFactory from '../autenticacao/interceptador/axiosFactory';
import axios from 'axios';
import Cache from '../cache';

const getHeaders = () => {
    const token = Cache.getAccessToken();
    return { Authorization: `Bearer ${token}`, 'Content-Type': 'application/json' };
};

const RequestClient = (config) => {
    const BASE_URL = config.url_base_api_oauth2;
    const axiosComInterceptador = AxiosFactory.create(config);

    /**
     * Requisições com interceptador.
     * 1. Caso retorne token inválido, tenta uma segunda vez com a renovação do token a partir do refresh token.
     * 2. Não é obrigatório adicionar o Bearer Token no header da requisiçãõ. Pois já estão adicionados.
     */
    const me = async () => axios.get(`${BASE_URL}/me`);

    return {
        me
    };
};

export default RequestClient;