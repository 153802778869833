import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import { createDropdown } from '@ckeditor/ckeditor5-ui';
import { Locale } from '@ckeditor/ckeditor5-utils';
import icon from '../../icones/webspeech.svg';
import iconOn from '../../icones/microphone-on.svg';

export default class WebSpeechButton extends Plugin {
    constructor(editor, options) {
        super(editor, options);
        this.isListening = false;
        this.recognition = null;
        this.currentCulture = { val: 'pt-BR', langVal: 6 };
    }

    init() {
        const editor = this.editor;

        if ('webkitSpeechRecognition' in window && /^((?!chrome|android).)*safari/i.test(navigator.userAgent)) { // Funciona apenas no Safari. O Chrome está com problema na API desde 01/2023 sem solução até a presente data.
            editor.ui.componentFactory.add('reconhecimentoVoz', () => {
                const locale = new Locale();
                const dropdown = createDropdown(locale);

                dropdown.buttonView.set({
                    withText: false,
                    label: 'WebSpeech',
                    icon: icon,
                    tooltip: true
                });

                this.recognition = new webkitSpeechRecognition();
                this.recognition.continuous = true;
                this.recognition.interimResults = false;
                this.recognition.lang = 'pt-BR';

                this.recognition.onstart = () => {
                    console.log('Iniciando reconhecimento...');
                    this.isListening = true;
                    dropdown.buttonView.set({
                        withText: false,
                        label: 'WebSpeech',
                        icon: iconOn,
                        tooltip: true
                    });
                };

                this.recognition.onerror = (event) => {
                    console.error('Erro no reconhecimento:', event);
                };

                this.recognition.onresult = (event) => {
                    for (var i = event.resultIndex; i < event.results.length; ++i) {
                        if (event.results[i].isFinal) {
                            const transcript = event.results[i][0].transcript;

                            const viewFragment = editor.data.processor.toView(transcript.replace(/\n/g, '<br />'));
                            const modelFragment = editor.data.toModel(viewFragment);
                            console.log(modelFragment);
                            editor.model.insertContent(modelFragment, editor.model.document.selection);
                        }
                    }
                };

                this.recognition.onend = () => {
                    console.log('Reconhecimento encerrado.');
                    this.isListening = false;
                    dropdown.buttonView.set({
                        withText: false,
                        label: 'WebSpeech',
                        icon: icon,
                        tooltip: true
                    });
                };

                dropdown.buttonView.on('execute', () => {
                    if (!this.isListening) {
                        this.recognition.start();
                    } else {
                        this.recognition.stop();
                    }
                });

                return dropdown;
            });
        } else {
            console.warn('A API Web Speech não está disponível neste navegador.');
        }
    }

}
