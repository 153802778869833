"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
Object.defineProperty(exports, "validationSchema", {
  enumerable: true,
  get: function get() {
    return _validationSchema["default"];
  }
});
var _lib = _interopRequireDefault(require("./lib"));
var _validationSchema = _interopRequireDefault(require("./lib/validationSchema"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { "default": e }; }
/** Componente */
var _default = exports["default"] = _lib["default"];