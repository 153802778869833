"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "SttCkEditor", {
  enumerable: true,
  get: function get() {
    return _SttCkEditor["default"];
  }
});
Object.defineProperty(exports, "SttExamesCkEditor", {
  enumerable: true,
  get: function get() {
    return _SttExamesCkEditor["default"];
  }
});

var _SttCkEditor = _interopRequireDefault(require("./lib/SttCkEditor"));

var _SttExamesCkEditor = _interopRequireDefault(require("./lib/SttExamesCkEditor"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }