const alofonoComum = {
    alofono: 'AloFono',
    emailAlofono: 'alofono.css@contato.ufsc.br',
    iniciarTriagemAuditiva: 'Iniciar Triagem Auditiva',
    sobreAlofono: 'Sobre o AloFono',
    sobreTriagemAuditiva: 'Sobre a Triagem Auditiva',
    testeDigitoRuido: 'Teste de Dígito no Ruído',
    perguntaObrigatoria: 'Você deve responder esta pergunta para continuar o teste',
};

module.exports = {
    Cabecalho: {
        iniciarTriagemAuditiva: alofonoComum.iniciarTriagemAuditiva,
        sobreAlofono: alofonoComum.sobreAlofono,
        sobreTriagemAuditiva: alofonoComum.sobreTriagemAuditiva,
        duvidaSairTeste: 'Você tem certeza que deseja sair do teste? Todos os passos do teste já realizados deverão ser refeitos'
    },
    ConfiguracaoDispositivo: {
        balaoIniciarConfiguracao: 'Lembre-se de ir a um lugar silencioso para realizar a sua Triagem Auditiva!',
        tituloCompreendeBemConversaAmbientesRuidosos: '2 - Você compreende bem uma conversa em ambientes ruidosos?',
        tituloConsideraProblemaAudicao: '1 - Você considera que tenha algum problema de audição?',
        vamosIniciar: 'Vamos iniciar!',
        perguntaObrigatoria: alofonoComum.perguntaObrigatoria
    },
    PreparandoTeste: {
        acioneBotaoOuvir: 'Acione o botão ▶️ para ouvir',
        aguardeAudioDownload: 'Carregando arquivos de áudios. Isso poderá levar alguns minutos, aguarde.',
        avisoErroIniciar: 'Não foi possível iniciar o teste. Verifique sua conexão com a internet e tente novamente.',
        circoAuricular: 'Circo-auricular (acoplado a orelha)',
        entendi: 'Entendi',
        foneConectado: 'O fone de ouvido está conectado',
        iniciarTeste: 'Iniciar o Teste de Dígito no Ruído',
        intraAuricular: 'Intra-auricular (dentro da orelha) ',
        localSilencioso: 'Estou em um local silencioso',
        marcarItensObrigatorio: 'Você deve marcar ambos itens para continuar o teste',
        modalProblemasSomTexto: 'Se você está com dificuldade para ouvir o som, verifique o seguinte:',
        modalProblemasSomTextoA: 'a) O ajuste do som do seu dispositivo está correto?',
        modalProblemasSomTextoB: 'b) A aba do seu navegador está silenciada?',
        modalProblemasSomTextoC: 'c) O seu dispositivo direciona o som para o seu fone de ouvido?',
        modalProblemasSomTitulo: 'Atenção',
        perguntaObrigatoria: alofonoComum.perguntaObrigatoria,
        preparandoTeste: 'Preparando o seu teste!',
        reguleVolumeFone: 'Regule o volume do seu fone de ouvido para um nível confortável!',
        supraAuricular: 'Supra-auricular (por cima da orelha)',
        textoIniciarTesteDigito: 'A seguir, você escutará uma sequência de três números em meio a um ruído. Para realizar o teste você deverá acionar o botão play ▶️, escutar a sequência de números e digitar o que escutou. Só é permitido ouvir a sequência de números uma vez.',
        textoNaoEntenderNumero: 'Se você não conseguir entender algum número, deverá digitar qualquer número no teclado para continuar no teste!',
        textoNaoOuvirSom: 'Se você não ouvir o som, selecione o botão de ajuda!',
        titulo: 'Verifique os itens/condições abaixo antes de iniciar o teste',
        tituloTipoFone: 'Indique qual fone de ouvido você está utilizando',
        voceConsegueOuvirSom: 'Você consegue ouvir o som?'
    },
    Rodape: {
        alofono: alofonoComum.alofono,
        emailAlofono: alofonoComum.emailAlofono,
        emCasoDuvidas: 'Em caso de dúvidas, entre em contato',
        iniciarTriagemAuditiva: alofonoComum.iniciarTriagemAuditiva,
        sobreTriagemAuditiva: alofonoComum.sobreTriagemAuditiva
    },
    TelaBoasVindas: {
        alofono: alofonoComum.alofono,
        descricaoQuestionarioHabilidadeExperienciaAuditiva: 'que avalia as suas experiências auditivas em situações reais de comunicação',
        descricaoSistema: 'O alôfono é um sistema que tem a proposta de realizar uma Triagem Auditiva para adultos. Ao final da triagem você conhecerá o resultado e receberá orientações.',
        descricaoTesteDigitoRuido: 'que avalia a habilidade do indivíduo reconhecer um som apresentado em meio a uma situação de ruído competitivo.',
        etapaDois: '2 - Verificação do som',
        etapaQuatro: '4 - Resultado do teste',
        etapaQuestionarioDois: '2 - Resultado do questionário',
        etapaQuestionarioUm: '1 - Perguntas do questionário',
        etapasQuestionarioHabilidadeExperienciaAuditiva: 'Etapas do questionário de habilidade e experiência auditiva',
        etapasTesteDigitoRuido: 'Etapas do Teste de Dígito no Ruído',
        etapasTriagemAuditiva: 'Etapas da Triagem Auditiva',
        etapaTres: '3 - Teste de Dígito no Ruído',
        etapaUm: '1 - Checagem das condições técnicas',
        iniciarTriagemAuditiva: alofonoComum.iniciarTriagemAuditiva,
        observeEtapasCadaTeste: 'Observe a seguir as etapas em cada teste para realizar a Triagem Auditiva',
        questionarioHabilidadeExperienciaAuditiva: 'Questionário de habilidade e experiência auditiva',
        sobreTriagemAuditiva: alofonoComum.sobreTriagemAuditiva,
        testeDigitoRuido: alofonoComum.testeDigitoRuido,
        tituloSejaBemVindo: 'Seja bem-vindo!',
        triagemAuditivaDuasEtapas: 'A Triagem Auditiva consiste na realização de um:',
        mensagensBrowser: {
            browserIncompativel: 'Detectamos que você possui um navegador não suportado pelo nosso sistema e por isso não podemos garantir a eficácia do teste. Sugerimos que você utilize a versão mais recente do navegador Google Chrome ou Mozilla Firefox para realização do teste.',
            androidAntigo: 'Detectamos que você está utilizando uma versão desatualizada do seu dispositivo Android e por isso não podemos garantir a eficácia do teste. Sugerimos que você atualize o seu celular/tablet ou utilize outro dispositvo mais atualizado para realização do teste.',
            iosAntigo: 'Detectamos que você está utilizando uma versão desatualizada do seu dispositivo Apple e por isso não podemos garantir a eficácia do teste. Sugerimos que você atualize o seu celular/tablet ou utilize outro dispositvo mais atualizado para realização do teste.',
            chromeAntigo: 'Detectamos que você está utilizando uma versão desatualizada do navegador Google Chrome e por isso não podemos garantir a eficácia do teste. Sugerimos que você atualize o seu navegador antes de prosseguir com o teste.',
            firefoxAntigo: 'Detectamos que você está utilizando uma versão desatualizada do navegador Mozilla Firefox e por isso não podemos garantir a eficácia do teste. Sugerimos que você atualize o seu navegador antes de prosseguir com o teste.',
            safariAntigo: 'Detectamos que você está utilizando uma versão desatualizada do navegador Safari e por isso não podemos garantir a eficácia do teste. Sugerimos que você atualize o seu navegador antes de prosseguir com o teste.',

        }
    },
    Teste: {
        acioneBotaoOuvir: 'Acione o botão ▶️ para escutar os números',
        carregandoProximoPasso: 'Aguarde enquanto preparamos o próximo passo do teste',
        carregandoResultados: 'Aguarde enquanto estamos carregando os resultados do teste',
        testeDigitoRuido: alofonoComum.testeDigitoRuido,
        verificarAmbienteTeste: 'Parece que você está tendo problemas com o teste. Regule seu volume e vá para um local silencioso para continuar'
    },
    Triagem: {
        agradecimento: 'Obrigado por ter realizado a Triagem Auditiva conosco!',
        avisoErroSalvar: 'Não foi possível salvar o resultado do teste. Deseja tentar novamente? Caso você clique em "Não", o resultado do seu teste será perdido.',
        continuar: 'Continuar',
        continuarTriagemIniciarQustionario: 'Para continuar a Triagem Auditiva, clique em "Iniciar questionário"',
        entrarEmContato: 'Você autoriza a equipe do alôfono entrar em contato?',
        feedbackLaranjaTeste: 'o resultado desse teste sugere que a sua audição precisa de uma avaliação completa!',
        feedbackLaranjaTriagem: 'o resultado da Triagem Auditiva sugere que a sua audição precisa de uma avaliação completa!',
        feedbackVerdeTeste: 'o resultado desse teste sugere que sua <i>audição social</i> está adequada!',
        feedbackVerdeTriagem: 'o resultado da Triagem Auditiva sugere que sua <i>audição social</i> está adequada!',
        finalizarTriagem: 'Finalizar Triagem Auditiva',
        iniciarQuestionario: 'Iniciar questionário',
        mensagemUnidadeSaude: 'Você pode buscar orientação com um médico ou fonoaudiólogo da rede pública ou privada.',
        Questionario: {
            deModoAlgum: 'De modo algum',
            descricao: 'Serão apresentadas para você algumas perguntas relacionadas a situações auditivas do seu dia-a-dia. Você deverá dar uma nota de 0 a 10 para o seu desempenho comunicativo onde 10 significa que você é perfeitamente capaz de executar o que está escrito e 0 quando você não conseguir realizar o que está sendo perguntado.',
            descritivoPergunta_1: 'Você consegue conversar com alguém quando há outra pessoa falando e que tem o mesmo tom de voz da pessoa que conversa com você?',
            descritivoPergunta_2: 'Você está sentado entre duas pessoas. Uma delas começa a falar. Você consegue dizer imediatamente se é a pessoa da sua direita ou a da sua esquerda que está falando, sem precisar olhar? ',
            descritivoPergunta_3: 'Você consegue dizer o quanto um ônibus ou um caminhão está longe, a partir do seu som?',
            descritivoPergunta_4: 'Os sons do dia a dia que você consegue ouvir com facilidade são claros (não turvos)?',
            descritivoPergunta_5: 'Você tem que se concentrar muito quando está escutando alguém ou alguma coisa?',
            naoPrecisaConcentrar: 'Não precisa se concentrar muito',
            perfeitamente: 'Perfeitamente',
            precisaConcentrar: 'Precisa se concentrar muito',
            resultado: 'Resultado do questionário',
            titulo: 'Questionário de habilidade e experiência auditiva',
            tituloPergunta: 'Pergunta'
        },
        resultado: 'Resultado final da Triagem auditiva',
        resultadoTriagem: 'Resultado da Triagem auditiva',
        resultadoTesteRuido: 'Resultado do Teste de Dígito no Ruído'
    }
}