'use strict';

const HttpStatus = require('http-status-codes');
const { PESQUISA } = require('../constantes');

const Servico = {

    /**
     * @deprecated Função antiga para busca de dados para grids. 
     * Descontinuada para uma nova versão que atende melhor os conceitos de APIs REST.
     * @returns 
     */
    executarBusca: async (reply, validador, path, mensagemNenhumResultado, funcaoBusca, params = {}) => {
        const resposta = {
            message: null,
            data: {},
            errors: []
        };

        if (validador) {
            const { error } = validador.validate(params);
            if (error) {
                error.details.forEach(detail => {
                    const erro = {
                        path: path,
                        message: detail.message
                    };
                    resposta.errors.push(erro);
                });
                return reply.response(resposta).code(HttpStatus.UNPROCESSABLE_ENTITY);
            }
        }

        const resultadoContagem = await funcaoBusca(params, true);
        const { count } = resultadoContagem[0];

        if (parseInt(count) === PESQUISA.SEM_RESULTADO) {
            const error = {
                path: path,
                message: mensagemNenhumResultado ? mensagemNenhumResultado : PESQUISA.MENSAGEM_SEM_RESULTADO
            };
            resposta.errors.push(error);
            return reply.response(resposta).code(HttpStatus.NOT_FOUND);
        }

        const resultado = await funcaoBusca(params);
        resposta.data = {
            totalRegistros: count,
            itens: resultado
        };

        return reply.response(resposta).code(HttpStatus.OK);
    },

    executarBuscaDados: async ({ reply, validador = null, path, funcaoBusca, params = {} }) => {
        const resposta = {
            message: null,
            data: {},
            errors: []
        };

        if (validador) {
            const { error } = validador.validate(params);
            if (error) {
                error.details.forEach(detail => {
                    const erro = {
                        path: path,
                        message: detail.message
                    };
                    resposta.errors.push(erro);
                });
                return reply.response(resposta).code(HttpStatus.UNPROCESSABLE_ENTITY);
            }
        }

        const resultadoContagem = await funcaoBusca(params, true);
        const { count } = resultadoContagem[0];

        if (parseInt(count) === PESQUISA.SEM_RESULTADO) {
            resposta.data = {
                totalRegistros: PESQUISA.SEM_RESULTADO,
                itens: []
            };

            return reply.response(resposta).code(HttpStatus.OK);
        }

        const resultado = await funcaoBusca(params);
        resposta.data = {
            totalRegistros: count,
            itens: resultado
        };

        return reply.response(resposta).code(HttpStatus.OK);
    },

};

module.exports = Servico;