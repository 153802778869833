const apac = require('./apac');
const dermato = require('./dermato');
const ecg = require('./ecg');
const eeg = require('./eeg');
const estomato = require('./estomato');
const teleconsultorias = require('./teleconsultorias');
const tfd = require('./tfd');
const tfdPassagens = require('./tfdPassagens');
const oauth2 = require('./oauth2');
const exames = require('./exames');
const comum = require('./comum');
const administrativo = require('./administrativo');
const teleEducacao = require('./teleEducacao');
const mensagens = require('./mensagens');
const alofono = require('./alofono');
const analise = require('./analise');
const espiro = require('./espiro');
const teleatendimento = require('./teleatendimento');
const teleatendimentoFhir = require('./teleatendimentoFhir');
const meusExames = require('./meusExames');
const polissonografia = require('./polissonografia');
const helpdesk = require('./helpdesk');
const oxigenoterapia = require('./oxigenoterapia');
const valida = require('./valida');
const conecta = require('./conecta');

module.exports ={
    administrativo,
    alofono,
    analise,
    apac,
    comum,
    conecta,
    dermato,
    ecg,
    eeg,
    espiro,
    estomato,
    exames,
    helpdesk,
    mensagens,
    meusExames,
    oauth2,
    oxigenoterapia,
    polissonografia,
    teleatendimento,
    teleatendimentoFhir,
    teleconsultorias,
    teleEducacao,
    tfd,
    tfdPassagens,
    valida
};