import axios from 'axios';
import Cache from '../cache';

const getHeaders = () => {
    const token = Cache.getAccessToken();
    return { Authorization: `Bearer ${token}`, 'Content-Type': 'application/json' };
};

const RequestClient = (config) => {
    const BASE_URL = config.url_base_api_oauth2;

    const me = async () => axios.get(`${BASE_URL}/me`, { headers: getHeaders() });
    const restaurarTempoAcesso = async () => axios.get(`${BASE_URL}/interno/restaurar-tempo-acesso`, { headers: getHeaders() });
    const verificarTempoAcesso = async () => axios.get(`${BASE_URL}/interno/verificar-tempo-acesso`, { headers: getHeaders() });
    const logout = async () => axios.get(`${BASE_URL}/logout`, { headers: getHeaders() });

    return {
        me, restaurarTempoAcesso, verificarTempoAcesso, logout
    };
};

export default RequestClient;