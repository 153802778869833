export default {
    toolbar: [
        'heading',
        '|',
        'bold',
        'italic',
        'strikethrough',
        'underline',
        'link',
        'bulletedList',
        'numberedList',
        '|',
        'outdent',
        'indent',
        '|',
        'expressoesMatematicas',
        'atalhos',
        'reconhecimentoVoz',
        'insertTable',
        'imageUpload',
        'undo',
        'redo',
        'alignment',
        'findAndReplace',
        'fontBackgroundColor',
        'fontColor',
        'fontFamily',
        'fontSize',
        'horizontalLine',
        'removeFormat',
    ],
    language: 'pt-br',
    table: {
        contentToolbar: [
            'tableColumn',
            'tableRow',
            'mergeTableCells',
            'tableProperties'
        ]
    },
    image: {
        styles: [
            'alignCenter',
            'alignLeft',
            'alignRight'
        ],
        resizeOptions: [
            {
                name: 'resizeImage:original',
                label: 'Original',
                value: null
            },
            {
                name: 'resizeImage:25',
                label: '25%',
                value: '25'
            },
            {
                name: 'resizeImage:50',
                label: '50%',
                value: '50'
            },
            {
                name: 'resizeImage:75',
                label: '75%',
                value: '75'
            }
        ],
        toolbar: [
            'imageTextAlternative',
            'toggleImageCaption',
            '|',
            'imageStyle:inline',
            'imageStyle:wrapText',
            'imageStyle:breakText',
            '|',
            'resizeImage'
        ],
        insert: {
            type: 'inline'
        }
    }
}