"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var yup = _interopRequireWildcard(require("yup"));
var _stringSimilarityJs = require("string-similarity-js");
var _fieldNames = require("./fieldNames");
function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }
function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { "default": obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj["default"] = obj; if (cache) { cache.set(obj, newObj); } return newObj; }
function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
var _default = function _default(props) {
  var _yup$object$shape;
  var strings = props.strings,
    nomeUsuario = props.nomeUsuario,
    dataNascimentoUsuario = props.dataNascimentoUsuario,
    cpfUsuario = props.cpfUsuario,
    loginUsuario = props.loginUsuario,
    emailUsuario = props.emailUsuario;
  var testeSimilaridade = function testeSimilaridade(_ref, _ref2) {
    var novaSenha = _ref.novaSenha;
    var dado = _ref2.dado,
      tipo = _ref2.tipo;
    var senha_formatada = novaSenha === null || novaSenha === void 0 ? void 0 : novaSenha.replace(/\ /g, "");
    var limte_similaridade = 0.6;
    var similaridade = 0;
    if (!novaSenha || !dado) {
      return true;
    }
    if (tipo === "nome") {
      senha_formatada = novaSenha.replace(/\d/g, "").replace(/[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]/g, "");
      limte_similaridade = 0.4;
    }
    if (tipo === "data" || tipo === "cpf") {
      senha_formatada = novaSenha.replace(/[a-zA-Z]/g, "").replace(/[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]/g, "");
    }
    if (tipo === "login") {
      if (!/[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]/.test(dado)) {
        senha_formatada = senha_formatada.replace(/[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]/g, "");
      }
      if (!/[A-Za-z]/.test(dado)) {
        senha_formatada = senha_formatada.replace(/[A-Za-z]/g, "");
      }
      if (!/\d/.test(dado)) {
        senha_formatada = senha_formatada.replace(/\d/g, "");
      }
    }
    if (tipo === "email") {
      if (!/@.*\.com$/.test(senha_formatada)) {
        dado = dado.replace(/@.*\.com$/, "");
      }
    }
    similaridade = (0, _stringSimilarityJs.stringSimilarity)(senha_formatada, dado);
    if (similaridade >= limte_similaridade) {
      return false;
    } else {
      return true;
    }
  };
  var testeNovaSenhaConfirmacaoIguais = function testeNovaSenhaConfirmacaoIguais(parent) {
    if (!parent.novaSenha || !parent.confirmacaoSenha) {
      return true;
    }
    return parent.novaSenha === parent.confirmacaoSenha;
  };
  return yup.object().shape((_yup$object$shape = {}, _defineProperty(_yup$object$shape, _fieldNames.NOVA_SENHA, yup.string().nullable().trim().required(strings.campoObrigatorio).min(8, strings.senhaMinimo9Caracteres).matches(/^[^\s]+$/, strings.senhaSemEspacos).matches(/\d/, strings.senhaAoMenosUmNumero).matches(/[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]/, strings.senhaAoMenosUmCaracter).matches(/[a-z]/, strings.senhaAoMenosUmaMinuscula).matches(/[A-Z]/, strings.senhaAoMenosUmaMaiuscula).test("senhaNaoContemNome", strings.novaSenhaConfirmacaoNome, function () {
    return testeSimilaridade(this.parent, config = {
      dado: nomeUsuario,
      tipo: "nome"
    });
  }).test("senhaNaoContemDataNascimento", strings.novaSenhaConfirmacaoDataNascimento, function () {
    return testeSimilaridade(this.parent, config = {
      dado: dataNascimentoUsuario,
      tipo: "data"
    });
  }).test("senhaNaoContemCpf", strings.novaSenhaConfirmacaoCpf, function () {
    return testeSimilaridade(this.parent, config = {
      dado: cpfUsuario,
      tipo: "cpf"
    });
  }).test("senhaNaoContemLogin", strings.novaSenhaConfirmacaoLogin, function () {
    return testeSimilaridade(this.parent, config = {
      dado: loginUsuario,
      tipo: "login"
    });
  }).test("senhaNaoContemEmail", strings.novaSenhaConfirmacaoEmail, function () {
    return testeSimilaridade(this.parent, config = {
      dado: emailUsuario,
      tipo: "email"
    });
  }).test("senhasIguais", "", function () {
    return testeNovaSenhaConfirmacaoIguais(this.parent);
  })), _defineProperty(_yup$object$shape, _fieldNames.CONFIRMACAO_SENHA, yup.string().nullable().trim().required(strings.campoObrigatorio).test("senhasIguais", strings.novaSenhaConfirmacaoSenhaIgual, function () {
    return testeNovaSenhaConfirmacaoIguais(this.parent);
  })), _yup$object$shape));
};
exports["default"] = _default;