/**
 * Interceptador para as requisições com token.
 * Funções interceptadoras para uma segunda tentativa de refresh token para axios.
 * 1. No caso de token inválido, o cliente faz uma tentativa de renovação de token a partir do refresh token. 
 * 2. Caso o refresh token falhe, o algoritmo remove os tokens do localstorage, e procede com o erro.
 */
import axios from 'axios';
import Cache from '../../cache';

const getHeaders = () => {
    const token = Cache.getAccessToken();
    return { Authorization: `Bearer ${token}`, 'Content-Type': 'application/json' };
};

class AxiosFactory {
    constructor(config) {
        this.config = config;
        this.api = axios.create({
            headers: getHeaders()
        });
    }

    addRefreshTokenInterceptorResp() {
        const self = this;
        const success = (response) => response;
        const fail = async (error) => {
            const access_token = Cache.getAccessToken();
            const refresh_token = Cache.getRefreshToken();
            if (error.response.status === 401 && access_token && refresh_token) {
                const header = {
                    "Content-Type": "application/json"
                };
                const parameters = {
                    method: "POST",
                    headers: header,
                };
                const body = {
                    grant_type: "refresh_token",
                    refresh_token,
                };
                try {
                    const res = await axios
                        .post(
                            `${this.config.url_base_api_oauth2}/auth/refresh_token`,
                            body,
                            parameters
                        );
                    Cache.setAccessToken(res.data.access_token);
                    Cache.setRefreshToken(res.data.refresh_token);
                    error.config["headers"] = { ...error.config["headers"], ...getHeaders() }
                    return self.api.request(error.config); // Tentar uma segunda vez com o token atualizado.
                } catch (err) {
                    Cache.clear();
                    return Promise.reject(error);
                }
            }
            return Promise.reject(error);
        };
        this.api.interceptors.response.use(success, fail);
        return this;
    }

    build() {
        return this.api;
    }
}

const create = (config) => {
    return new AxiosFactory(config).addRefreshTokenInterceptorResp().build();
}

export default { create };