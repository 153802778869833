import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import { Model, addListToDropdown, createDropdown } from '@ckeditor/ckeditor5-ui';
import { Collection, Locale } from '@ckeditor/ckeditor5-utils';
import icon from '../../icones/shortcut.svg';

export default class Atalhos extends Plugin {
    init() {
        const editor = this.editor;
        const atalhos = editor.config.get('atalhos');
        editor.ui.componentFactory.add( 'atalhos', () => {

            const locale = new Locale();
            const items = new Collection();
    
            const dropdown = createDropdown(locale);
            
            dropdown.buttonView.set({
                withText: false,
                label: 'Atalhos',
                icon: icon,
                tooltip: true
            });

            atalhos.forEach(item => {
                items.add({
                    type: 'button',
                    model: new Model({
                        withText: true,
                        label: item.atalho,
                        text: item.descricao
                    })
                });
            })
            addListToDropdown(dropdown, items);

            dropdown.on('execute', (event) => {
                const { text } = event.source;
                const viewFragment = editor.data.processor.toView(text);
                const modelFragment = editor.data.toModel(viewFragment);
                editor.model.insertContent(modelFragment, editor.model.document.selection);
            });

            dropdown.render();
            dropdown.panelView.element.style = 'max-height: 200px; overflow-y: auto;';
            return dropdown;
        });
    }
}