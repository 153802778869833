import RequestClient from '../requests/index';
import RequestClientSemRefreshToken from '../requests/clientSemRefreshToken';
import Cache from '../cache';

const useQuery = () => {
    return new URLSearchParams(window.location.search);
};

const login = async (config) => {
    /** get token */
    const query = useQuery();
    const tokenQuery = query.get('token');
    const refresh_token = query.get('refresh_token');
    const tokenLocalStorate = Cache.getAccessToken();
    const token = tokenLocalStorate || tokenQuery;

    if (refresh_token) {
        Cache.setRefreshToken(refresh_token);
    }

    if (token) {
        Cache.setAccessToken(token);
        return RequestClient(config).me();
    } else {
        throw new Error('O usuário não possui token.');
    }
}

const restaurarTempoAcesso = async (config) => {
    return RequestClientSemRefreshToken(config).restaurarTempoAcesso();
};

const verificarTempoAcesso = async (config) => {
    return RequestClientSemRefreshToken(config).verificarTempoAcesso();
};

const loginSemRefreshToken = async (config) => {
    /** get token */
    const query = useQuery();
    const tokenQuery = query.get('token');
    const refresh_token = query.get('refresh_token');
    const tokenLocalStorate = Cache.getAccessToken();
    const token = tokenLocalStorate || tokenQuery;

    if (refresh_token) {
        Cache.setRefreshToken(refresh_token);
    }

    if (token) {
        Cache.setAccessToken(token);
        return RequestClientSemRefreshToken(config).me();
    } else {
        throw new Error('O usuário não possui token.');
    }
}

const logout = async (config) => {
    const token = Cache.getAccessToken();
    if (token) {
        const response = await RequestClientSemRefreshToken(config).logout();
        return response;
    }
    return {};
}

export default { login, loginSemRefreshToken, logout, restaurarTempoAcesso, verificarTempoAcesso };