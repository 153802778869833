import { evaluate, parse } from 'mathjs';
import Command from '@ckeditor/ckeditor5-core/src/command';

export const calcularFormulas = (editor) => {
    let conteudo = editor.getData();
    let todosArray = conteudo.split('&lt;formula&gt;');

    for (const formula of todosArray) {
        try{
            var parseFormula = parse(formula);

            if(parseFormula.value || parseFormula.args){
                //Retira elementos duplicados do array
                var substring = formula.split('=');
                var formulaComResultado = null;

                //A fórmula deve obrigatoriamente ter de um a dois lados: ou ela tem atribuição ou não.
                var resultado = evaluate(formula);
                if(substring.length == 1){
                    formulaComResultado = resultado;
                }
                if(substring.length == 2){
                    formulaComResultado = substring[0] + ' = ' + resultado;
                }
                if(formulaComResultado){
                    conteudo = conteudo.replace('&lt;formula&gt;'+formula+'&lt;formula&gt;', formulaComResultado);
                }
            }
        } catch(e){}
    }

    editor.setData(conteudo, { mode: 'change' });
}

export class CalcularFormulas extends Command {
    execute() {
        calcularFormulas(this.editor);
    }
}