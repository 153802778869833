'use strict';

const LogAplicacao = {

    tabela: 'public.log_aplicacao',

    inserir: async (dbClient, transactionHandler = null, dados) => {
        const { codigo, evento, perfis = null, url, prioridade, arquivo, linha } = dados;
        
        let insert = dbClient.insert({
            codigo: codigo,
            evento: evento,
            lista_perfis: perfis,
            url: url,
            prioridade: prioridade,
            arquivo: arquivo,
            linha: linha
        })
            .into(LogAplicacao.tabela);
    
        if (transactionHandler) {
            insert = insert.transacting(transactionHandler);
        }
        await insert;
    }
};

module.exports = LogAplicacao;