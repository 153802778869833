module.exports = {
    agendamentoCnrac: 'Agendamento via CNRAC',
    agendamentoLocal: 'Agendamento local',
    agendamentoSolicitado: 'Agendamento solicitado',
    aguardandoAgendamento: 'Aguardando agendamento',
    aguardandoAgendamentoPeloAgendador: 'Aguardando regulação regional',
    aguardandoAgendamentoTfdEstadual: 'Aguardando agendamento pelo TFD estadual',
    aguardandoAvaliacaoTfdAvaliador: 'Aguardando avaliação pelo avaliador',
    aguardandoAvaliacaoTfdEstadual: 'Aguardando avaliação do TFD estadual',
    aguardandoAvaliacaoTfdRegulador: 'Aguardando avaliação pelo regulador',
    aguardandoEnvioCnrac: 'Aguardando agendamento da CNRAC',
    aguardandoNovamenteAvaliacaoTfdEstadual: 'Aguardando nova avaliação do TFD estadual',
    aguardandoRegulacao: 'Aguardando regulação',
    aguardarAgendamento: 'Aguardar agendamento',
    aguardarAgendamentoCnrac: 'Aguardar agendamento da CNRAC',
    ambulatorial: 'Ambulatorial',
    anexarComprovanteComparecimento: 'Anexar comprovante de comparecimento',
    anexarComprovanteComparecimento: 'Anexar comprovante de comparecimento',
    anoProcesso: 'Ano do processo',
    anoProcessoSgpe: 'Ano do processo SGPE',
    anoProcessoSgpeObrigatorio: 'O ano do processo SGPE é obrigatório.',
    arquivado: 'Arquivado',
    arquivarProcesso: 'Arquivar processo',
    autorizado: 'Autorizado',
    avaliador: 'Avaliador',
    cancelado: 'Cancelado',
    cancelamento: 'Cancelamento',
    caracterizacaoEventualUrgencia: 'Caracterização de eventual urgência na realização do procedimento',
    caraterAtendimento: 'Caráter de atendimento',
    caraterAtendimentoHospitalar: 'Categoria do atendimento hospitalar',
    caraterAtendimentoObrigatorio: 'O caráter de atendimento é obrigatório.',
    categoriaAtendimentoHospitalar: 'Categoria do atendimento hospitalar',
    categoriaAtendimentoHospitalarObrigatoria: 'A categoria do atendimento hospitalar é obrigatória.',
    cidadeAgendadoraObrigatoria: 'A cidade agendadora é obrigatória.',
    cidadeDestino: 'Município de destino',
    cidadeOrigem: 'Município de origem',
    classeFuncionalInsuficienciaCardiaca: 'Classe funcional da insuficiência cardíaca (NYHA) (obrigatório para CNRAC)',
    comAgendamento: 'Com agendamento',
    condicoesJustificamInternacao: 'Condições que justificam a internação/consulta',
    confirmacaoAgendamentoCnrac: 'Você confirma que este processo está aguardando o agendamento da CNRAC?',
    confirmacaoAguardandoAgendamento: 'Você confirma que este processo está aguardando agendamento?',
    confirmacaoRealizacaoAgendamento: 'Você confirma que este processo teve seu agendamento realizado?',
    confirmarAgendamento: 'Confirmar agendamento',
    conselhoTrabalhoRegulador: 'Conselho de trabalho do regulador',
    dadosGerais: 'Dados gerais',
    dadosInterestadual: 'Dados do processo interestadual',
    dadosJudicial: 'Dados do processo judicial',
    dadosJudicial: 'Dados judicial',
    dadosRequisitante: 'Dados do requisitante',
    dadosSolicitacao: 'Dados da solicitação',
    dadosTransporte: 'Dados do transporte',
    dataAtendimento: 'Data do atendimento',
    dataHoraAtendimento: 'Data e hora do atendimento',
    dataInclusaoProcesso: 'Data de inclusão do processo',
    dataPedido: 'Data do pedido',
    dataPedidoObrigatoria: 'A data do pedido é obrigatória.',
    dataPreenchimentoLaudoMedicoObrigatoria: 'A data de preenchimento do laudo médico é obrigatória.',
    devolucao: 'Devolução',
    devolvido: 'Devolvido',
    diagnosticoInicial: 'Diagnóstico inicial',
    diagnosticoRelacionadoProcedimentoSolicitado: 'Diagnóstico relacionado ao procedimento solicitado (obrigatório para CNRAC)',
    documentoAnexadoEngano: 'Documento(s) anexado(s) por engano.',
    eletivo: 'Eletivo',
    emAtualizacao: 'Em atualização',
    emTratamento: 'Em tratamento',
    encaminhamento: 'Encaminhamento',
    encaminharAvaliador: 'Encaminhar ao avaliador',
    encaminharProcessoPara: 'Encaminhar processo para',
    encaminharSisreg: 'Encaminhar ao SISREG',
    encaminharTfdEstadual: 'Encaminhar ao TFD Estadual',
    erro: 'Erro',
    erroBaixarArquivo: 'Não foi possível baixar o arquivo anexo. Tente novamente mais tarde.',
    erroCarregarSolicitacao: 'Não foi possível carregar a solicitação de TFD. Tente novamente mais tarde.',
    erroIniciarSolicitacao: 'Não foi possível inicializar a solicitação de TFD. Tente novamente mais tarde',
    erroOperacao: 'Não foi possível realizar a operação. Tente novamente mais tarde.',
    erroOperacao: 'Não foi possível realizar a operação. Tente novamente mais tarde.',
    erroProcedimentoSolicitadoObrigatorio: 'O procedimento solicitado é obrigatório.',
    erroRecuperacaoAgendamento: 'Ocorreu um erro ao recuperar o agendamento para esse processo. Você pode preenchê-lo manualmente ou tentar mais tarde.',
    erroRecuperacaoAvaliacao: 'Ocorreu um erro ao recuperar os dados de avaliação para esse processo. Você pode preenchê-lo manualmente ou tentar mais tarde.',
    erroRecuperacaoDadosRegulacao: 'Ocorreu um erro ao recuperar os dados de regulação para esse processo. Você pode preenchê-lo manualmente ou tentar mais tarde.',
    erroRecuperacaoMotivoCancelamento: 'Ocorreu um erro ao recuperar os motivos de cancelamento. Por favor, tente mais tarde.',
    erroRecuperacaoMotivoDevolucao: 'Ocorreu um erro ao recuperar os motivos de devolução. Por favor, tente mais tarde.',
    erroRecuperacaoProcedimentos: 'Ocorreu um erro ao recuperar os procedimentos solicitados. Por favor, tente mais tarde.',
    erroRecuperacaoProcessosTransporte: 'Ocorreu um erro ao recuperar os processos de transporte para este processo. Por favor, tente mais tarde.',
    erroSalvarSolicitacao: 'Não foi possível salvar a solicitação.',
    erroSalvarSolicitacaoAutomaticamente: 'Não foi possível salvar a solicitação automaticamente. Utilize a opção "Salvar rascunho" disponível no menu.',
    erroSolicitacaoPassagem: 'Não foi possível iniciar a solicitação de transporte.',
    erroUnidadeAtendimentoObrigatoria: 'A unidade de atendimento é obrigatória.',
    estadoNeurologicoSumario: 'Estado neurológico sumário',
    estadoOrigemObrigatorio: 'O estado de origem é obrigatório',
    exameFisico: 'Exame físico',
    examesComplementaresRealizados: 'Exames complementares realizados',
    funcaoRenalHepatica: 'Função renal e hepática',
    funcaoVentricular: 'Função ventricular (obrigatório para CNRAC)',
    funcionarioAvaliador: 'Funcionário avaliador',
    historiaPregressa: 'História pregressa e atual significativa, especialmente quanto ao uso e alergia a medicamentos, bem como alergia a contrastes anestésicos e anti-sépticos',
    historicoDoenca: 'Histórico da doença',
    hospitalar: 'Hospitalar',
    inativarComprovanteComparecimento: 'Inativar comprovante de comparecimento',
    inconclusivo: 'Inconclusivo',
    inconclusivoAnexarExames: 'Inconclusivo: Anexar exames',
    inconclusivoParecerAvaliador: 'Inconclusivo: Solicitar parecer do avaliador',
    informarNumeroCnrac: 'Informar número de laudo do CNRAC',
    informarNumeroCnrac: 'Informar número do CNRAC',
    informarProcedimentoSolicitado: 'Informar procedimento solicitado',
    inserirProcedimentoSolicitado: 'Inserir procedimento solicitado',
    justificarNecessidadeAcompanhante: 'Justificar em caso de necessidade de acompanhante',
    justificarRazoesLocalidade: 'Justificar as razões que impossibilitam a realização do tratamento/exames na localidade',
    justificativaNumeroAcompanhantesObrigatoria: 'A justificativa para o número de acompanhantes é obrigatória',
    justificativaVinculoTfd: 'Justificativa de vínculo com TFD',
    laudoProcesso: 'Laudo',
    laudoVencerMenos45Dias: 'Laudo a vencer em menos de 45 dias',
    laudoVencido: 'Laudo vencido',
    medicamentoUsoDose: 'Medicamento em uso e dose (obrigatório para CNRAC)',
    medicoSolicitante: 'Médico solicitante',
    medicoSolicitanteObrigatorio: 'O médico solicitante é obrigatório',
    mensagemAberturaProcesso: 'Você deseja iniciar uma nova solicitação? Ao confirmar, um novo rascunho será criado.',
    mensagemAlertaComecoSolicitacao: 'Você deseja iniciar uma nova solicitação? Ao confirmar, um novo rascunho será criado.',
    mensagemConfirmacaoProcedimentoSolicitado: 'Confirma a inserção do procedimento solicitado?',
    mensagemConfirmacaoProcedimentoSolicitado: 'Você confirma a seleção do procedimento solicitado?',
    mostrarProcessosVencidos: 'Mostrar processos vencidos',
    municipioOrigemObrigatorio: 'O município de origem é obrigatório.',
    negacao: 'Negação',
    negado: 'Negado',
    nenhumSigtapAdicionado: 'Nenhum SIGTAP adicionado',
    nenhumSigtapAdicionado: 'Nenhum SIGTAP adicionado',
    nomeTecnico: 'Nome do técnico',
    numeroAcompanhantesObrigatorioMenorIdade: 'Pacientes com menos de 18 anos devem ter ao menos um acompanhante',
    numeroAutos: 'Número dos autos',
    numeroAutosObrigatorio: 'O número dos autos é obrigatório.',
    numeroAutosVinteCaracteres: 'O número dos autos deve conter exatamente 20 caractéres.',
    numeroLaudoCnrac: 'Número do laudo do CNRAC',
    numeroPedidoExcederVinteCaracteres: 'O número do pedido não pode exceder 20 caracteres',
    numeroPedidoObrigatorio: 'O número do pedido é obrigatório.',
    numeroPedidoProcessoTfd: 'Número do pedido do processo de TFD',
    numeroProcessoSgpe: 'Número do processo SGPE',
    numeroRegistroSisreg: 'Número do registro do SISREG',
    numeroSgpeObrigatorio: 'O número do processo SGPE é obrigatório.',
    padraoRespiratorio: 'Padrão respiratório',
    periodoAtendimento: 'Período de atendimento',
    prazoCumprimentoProcesso: 'Prazo de cumprimento',
    preparoProcedimento: 'Preparo do procedimento',
    primeiroAtendimentoForaDomicilio: 'Primeiro atendimento fora do domicílio',
    procedimentoSigtap: 'Procedimentos SIGTAP',
    procedimentoSigtapObrigatorio: 'O procedimento SIGTAP é obrigatório.',
    procedimentoSolicitado: 'Procedimento solicitado',
    procedimentoSolicitadoPosSolicitacao: 'Procedimento solicitado (preenchido posteriormente a solicitação)',
    processoPassagem: 'Processo de passagem',
    processoSemUrgencia: 'Sem caráter de urgência',
    processoTfd: 'Processo de TFD',
    rascunho: 'Rascunho',
    redirecionarSolicitacaoPassagem: 'Você será redirecionado para a página de solicitação de um novo transporte.',
    redirecionarSolicitacaoPassagemExistente: 'Já há uma solicitação em rascunho para esta solicitação de TFD. Você será redirecionado para a página de alteração desse processo',
    regionalDestino: 'Regional de destino',
    registradoSisreg: 'Registrado no SISREG',
    registroSisreg: 'Registro SISREG',
    regulacao: 'Regulação',
    regulador: 'Regulador',
    regular: 'Regular',
    requisicaoSolicitacaoPassagem: 'Requisição da solicitação de passagem',
    semAgendamento: 'Sem agendamento',
    sendoAtualizacoPeloAgendador: 'Em atualização pelo TFD regional',
    sendoAtualizadoAgendamentoTfdEstadual: 'Sendo atualizado pelo TFD estadual',
    sendoAtualizadoAvaliador: 'Em atualização pelo avaliador',
    sendoAtualizadoCnracTfdEstadual: 'Sendo atualizado pelo TFD estadual',
    sendoAtualizadoNovamenteTfdEstadual: 'Em atualização novamente pelo TFD estadual',
    sendoAtualizadoTfdEstadual: 'Em atualização pelo TFD estadual',
    sendoAtualizadoTfdRegulador: 'Em atualização pelo regulador',
    siglaObrigatoria: 'A sigla é obrigatória.',
    siglaOrgaoSgpe: 'Sigla do órgão SGPE',
    sigtapAdicionado: 'SIGTAP adicionado',
    sigtapAdicionadoAnteriormente: 'Este SIGTAP já foi adicionado anteriormente.',
    situacaoPaciente: 'A situação do paciente é obrigatória.',
    situacaoPaciente: 'Situação do paciente',
    situacaoSolicitacao: 'Situação da solicitação',
    solicitacaoNovoTfd: 'Solicitação de novo processo de TFD',
    solicitacaoTfd: 'Solicitação de TFD',
    solicitacoesPassagem: 'Solicitações de passagem',
    solicitarTransporte: 'Solicitar Transporte',
    sucessoOperacao: 'Operação realizada com sucesso',
    sucessoOperacao: 'Operação realizada com sucesso',
    tipoAgendamento: 'Tipo de agendamento',
    tipoEncaminhamento: 'Tipo de encaminhamento',
    tipoProcesso: 'Tipo de processo',
    tipoProcessoObrigatorio: 'O tipo de processo é obrigatório.',
    tituloPesquisaTfd: 'Pesquisa de TFDs',
    todos: 'Todos',
    transporteRecomendadoObrigatorio: 'O transporte recomendável é obrigatório.',
    tratamentoRealizado: 'Tratamentos realizados',
    umTelefoneObrigatorio: 'Ao menos um número de telefone é obrigatório',
    unidadeAtendimento: 'Unidade de atendimento',
    unidadeAtendimentoHospitalar: 'Unidade de atendimento hospitalar',
    urgencia: 'Urgência',
    vinculoComprovante: 'Vínculo do comprovante'
};
